import { Col, Form, InputGroup } from "react-bootstrap";
import { forwardRef } from "react";

export const FieldGroup = forwardRef(
	(
		{
			md = "12",
			label,
			as,
			type = "text",
			beforeInput = null,
			afterInput = null,
			required,
			fieldClassName,
			className,
			error,
			onChange,
			...props
		},
		ref,
	) => {
		return (
			<Form.Group as={Col} md={md} className={className}>
				{label && (
					<Form.Label>
						{label} {required && <span className="asterisk">*</span>}
					</Form.Label>
				)}
				<InputGroup>
					{beforeInput && <InputGroup.Text>{beforeInput}</InputGroup.Text>}
					<div className="flex-grow-1 position-relative">
						<Form.Control
							ref={ref}
							as={as}
							type={type}
							className={fieldClassName}
							{...props}
							onChange={onChange}
						/>
						{error && (
							<Form.Control.Feedback type="invalid" tooltip>
								{error}
							</Form.Control.Feedback>
						)}
					</div>
					{afterInput && <InputGroup.Text>{afterInput}</InputGroup.Text>}
				</InputGroup>
			</Form.Group>
		);
	},
);

export default FieldGroup;
